import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaShoppingCart, FaUser, FaCalendar, FaBox, FaTag, FaFileInvoice, FaShoppingBag, FaCreditCard } from 'react-icons/fa';
import './OrdersList.css';

const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/shopify/get_orders', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrders(response.data.orders);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  const getStatusClass = (status) => {
    switch (status?.toLowerCase()) {
      case 'paid':
        return 'una-status-paid';
      case 'pending':
        return 'una-status-pending';
      default:
        return 'una-status-unfulfilled';
    }
  };

  if (error) return <div className="una-order-error">Error: {error}</div>;
  
  if (isLoading) {
    return (
      <div className="una-order-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-icons">
            <FaShoppingCart className="icon-primary" />
            <FaFileInvoice className="icon-secondary" />
            <FaCreditCard className="icon-tertiary" />
          </div>
          <h2>Loading Orders</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaShoppingCart />
              <span>Fetching Orders</span>
            </div>
            <div className="una-metric-item">
              <FaUser />
              <span>Loading Customer Data</span>
            </div>
            <div className="una-metric-item">
              <FaShoppingBag />
              <span>Processing Items</span>
            </div>
            <div className="una-metric-item">
              <FaCreditCard />
              <span>Verifying Payments</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-orders-container">
      <div className="una-orders-header">
        <div className="una-orders-title">
          <FaShoppingCart />
          <h2>Orders</h2>
        </div>
      </div>

      {isLoading ? (
        <div className="una-orders-loading-state">
          <div className="loading-spinner"></div>
          <p>Loading orders...</p>
        </div>
      ) : !orders.length ? (
        <div className="una-orders-empty-state">
          <FaShoppingCart className="empty-icon" />
          <p>No orders found</p>
        </div>
      ) : (
        <div className="una-orders-grid">
          {orders.map((order) => (
            <div 
              key={order.id} 
              className="una-order-card"
              onClick={() => handleOrderClick(order.id)}
            >
              <div className="una-order-card-header">
                <div className="una-order-number">#{order.order_number}</div>
                <div className="una-order-status-wrapper">
                  <span className={`una-order-status ${getStatusClass(order.financial_status)}`}>
                    {order.financial_status}
                  </span>
                  {order.fulfillment_status && (
                    <span className={`una-order-status ${getStatusClass(order.fulfillment_status)}`}>
                      {order.fulfillment_status}
                    </span>
                  )}
                </div>
              </div>

              <div className="una-order-card-content">
                <div className="una-order-info">
                  <FaCalendar />
                  <span>{new Date(order.created_at).toLocaleDateString()}</span>
                </div>

                <div className="una-order-info">
                  <FaUser />
                  <span>{order.customer.name || order.customer.email || 'N/A'}</span>
                </div>

                <div className="una-order-info una-order-price">
                  <strong>${order.total_price}</strong>
                </div>

                <div className="una-order-items-section">
                  <div className="una-order-section-header">
                    <FaBox />
                    <span>Items</span>
                  </div>
                  <ul className="una-order-items-list">
                    {order.line_items.map((item) => (
                      <li key={item.id} className="una-order-item">
                        <span className="una-order-item-title">{item.title}</span>
                        <div className="una-order-item-details">
                          <span>x{item.quantity}</span>
                          <span>${item.price}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                {order.tags && (
                  <div className="una-order-tags-section">
                    <div className="una-order-section-header">
                      <FaTag />
                      <span>Tags</span>
                    </div>
                    <div className="una-order-tags">
                      {order.tags.split(',').map((tag, index) => (
                        <span key={index} className="una-order-tag">
                          {tag.trim()}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrdersList;