import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaStore, FaExclamationTriangle, FaBoxes, FaTags, FaShoppingCart, FaBarcode, FaBoxOpen } from 'react-icons/fa';
import './ProductsList.css';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isOptimizing, setIsOptimizing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/shopify/get_products', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProducts(response.data.products);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleCheckboxChange = (productId) => {
    setSelectedProducts(prev => {
      if (prev.includes(productId)) {
        return prev.filter(id => id !== productId);
      } else {
        return [...prev, productId];
      }
    });
  };

  const handleOptimizeClick = async () => {
    if (selectedProducts.length === 0) {
      alert('Please select at least one product to optimize');
      return;
    }

    setIsOptimizing(true);
    try {
      const token = sessionStorage.getItem('access_token');
      
      // Convert numbers to strings
      const productIdsAsStrings = selectedProducts.map(id => id.toString());
      
      console.log('Sending optimization request with data:', productIdsAsStrings);
      
      const response = await axios.post(
        '/api/shopify/optimize_products', 
        { product_ids: productIdsAsStrings },
        {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('Optimization response:', response.data);

      const updatedProducts = await axios.get('/api/shopify/get_products', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProducts(updatedProducts.data.products);
      
      setSelectedProducts([]);
      alert('Products optimized successfully!');
    } catch (err) {
      console.error('Optimization error details:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status,
        requestData: selectedProducts
      });
      setError(`Failed to optimize products: ${err.message}. Status: ${err.response?.status}. Details: ${JSON.stringify(err.response?.data)}`);
    } finally {
      setIsOptimizing(false);
    }
  };

  const formatVariants = (variants) => {
    // Group variants by their options
    const groupedVariants = variants.reduce((acc, variant) => {
      const [size, color] = variant.title.split(' / ');
      if (size) acc.sizes.add(size);
      if (color) acc.colors.add(color);
      return acc;
    }, { sizes: new Set(), colors: new Set() });

    return {
      sizes: Array.from(groupedVariants.sizes),
      colors: Array.from(groupedVariants.colors)
    };
  };

  const hasLowStock = (variants) => {
    return variants.some(variant => variant.inventory_quantity < 10);
  };

  if (error) return <div className="una-product-error">Error: {error}</div>;

  if (isLoading) {
    return (
      <div className="una-product-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-icons">
            <FaStore className="icon-primary" />
            <FaBoxes className="icon-secondary" />
            <FaShoppingCart className="icon-tertiary" />
          </div>
          <h2>Loading Products</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaStore />
              <span>Fetching Store Products</span>
            </div>
            <div className="una-metric-item">
              <FaBoxOpen />
              <span>Processing Inventory</span>
            </div>
            <div className="una-metric-item">
              <FaBarcode />
              <span>Loading Variants</span>
            </div>
            <div className="una-metric-item">
              <FaTags />
              <span>Processing Tags</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-products-container">
      <div className="una-products-header">
        <div className="una-products-title">
          <FaStore />
          <h2>Products</h2>
        </div>
        <button 
          className="una-optimize-button"
          onClick={handleOptimizeClick}
          disabled={isOptimizing || selectedProducts.length === 0}
        >
          {isOptimizing ? 'Optimizing...' : 'Optimize Selected Products'}
        </button>
      </div>

      {isLoading ? (
        <div className="una-products-loading-state">
          <div className="loading-spinner"></div>
          <p>Loading products...</p>
        </div>
      ) : !products.length ? (
        <div className="una-products-empty-state">
          <FaStore className="empty-icon" />
          <p>No products found</p>
        </div>
      ) : (
        <div className="una-products-grid">
          {products.map((product) => {
            const { sizes, colors } = formatVariants(product.variants);
            return (
              <div key={product.id} className="una-product-card">
                <div className="una-product-checkbox-wrapper">
                  <input
                    type="checkbox"
                    className="una-product-checkbox"
                    checked={selectedProducts.includes(product.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleCheckboxChange(product.id);
                    }}
                  />
                </div>

                <div className="una-product-content" onClick={() => handleProductClick(product.id)}>
                  <div className="una-product-image-wrapper">
                    {product.images.length > 0 && (
                      <img 
                        src={product.images[0].src} 
                        alt={product.title}
                        className="una-product-image"
                      />
                    )}
                  </div>

                  <div className="una-product-details">
                    <h3 className="una-product-title">
                      {product.title}
                      {hasLowStock(product.variants) && (
                        <span className="una-variant-warning" title="Low stock warning">
                          <FaExclamationTriangle />
                        </span>
                      )}
                    </h3>

                    <div className="una-product-info">
                      <span className="una-product-type">{product.product_type}</span>
                      <span className="una-product-vendor">{product.vendor}</span>
                      <span className="una-product-status">{product.status}</span>
                    </div>

                    {(sizes.length > 0 || colors.length > 0) && (
                      <ul className="una-product-variants">
                        {sizes.length > 0 && (
                          <li className="una-product-variant-item">
                            Sizes
                            <span className="una-variant-count">{sizes.length}</span>
                          </li>
                        )}
                        {colors.length > 0 && (
                          <li className="una-product-variant-item">
                            Colors
                            <span className="una-variant-count">{colors.length}</span>
                          </li>
                        )}
                      </ul>
                    )}

                    <div className="una-product-tags">
                      {product.tags.split(',').map((tag, index) => (
                        <span key={index} className="una-product-tag">
                          {tag.trim()}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProductList;