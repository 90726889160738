// ChatbotSettingsPage.js
import React from 'react';
import ChatbotSettings from './ChatbotSettings'; // Importez votre composant ChatbotSettings

const ChatbotSettingsPage = () => {
  return (
    <div>
      <ChatbotSettings />
    </div>
  );
};

export default ChatbotSettingsPage;
