import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './login.css';
import { useTranslation } from 'react-i18next';



const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const checkUserAuthentication = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        if (token) {
          const response = await axios.get('/api/users/user', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.data.shop_name) {
            sessionStorage.setItem('shop', response.data.shop_name);
          }
          window.location.href = '/dashboard';
        }
      } catch (error) {
        sessionStorage.removeItem('access_token');
      }
    };

    checkUserAuthentication();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const formData = new URLSearchParams();
      formData.append('username', email);
      formData.append('password', password);

      const response = await axios.post('/api/auth/login', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      // Store access token
      sessionStorage.setItem('access_token', response.data.access_token);

      // Fetch user data
      const userResponse = await axios.get('/api/users/user', {
        headers: {
          Authorization: `Bearer ${response.data.access_token}`,
        },
      });

      if (userResponse.data.shop_name) {
        sessionStorage.setItem('shop', userResponse.data.shop_name);
      }

      // Check if user is not admin before handling WMS token
      if (userResponse.data.role !== 'admin' && response.data.wms_token) {
        sessionStorage.setItem('wms_token', response.data.wms_token);
        
        // Verify storage before proceeding
        const storedWmsToken = sessionStorage.getItem('wms_token');
        console.log('Stored WMS token verification:', {
          received: response.data.wms_token,
          stored: storedWmsToken,
          matches: response.data.wms_token === storedWmsToken
        });
      }

      // Add a small delay to ensure storage is complete
      await new Promise(resolve => setTimeout(resolve, 100));
      
      // Final verification before navigation
      const finalCheck = {
        accessToken: sessionStorage.getItem('access_token'),
        wmsToken: userResponse.data.role === 'admin' ? true : sessionStorage.getItem('wms_token'),
        shop: sessionStorage.getItem('shop')
      };
      console.log('Final storage check before navigation:', finalCheck);

      // Navigate if tokens are properly stored based on user role
      if (finalCheck.accessToken && finalCheck.wmsToken) {
        window.location.href = '/dashboard';
      } else {
        console.error('Tokens not properly stored before navigation');
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <div className="login-header">
          <img src="/menu_logo.png" alt={t('logo')} className="logo" />
        </div>
        <h2>{t('signIn')}</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email">{t('email')}</label>
            <input
              type="email"
              id="email"
              placeholder={t('enterEmail')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{t('password')}</label>
            <input
              type="password"
              id="password"
              placeholder={t('enterPassword')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">{t('login')}</button>
        </form>
        <a href="/forgot-password">{t('forgotPassword')}</a>
      </div>
    </div>
  );
};

export default Login;
