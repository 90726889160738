import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './ChatbotTest.css';
import { FaPaperPlane } from 'react-icons/fa';

const ChatbotTest = () => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (token) {
      fetchUserData(token);
    }
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
      fetchMessages(response.data._id);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null);
    }
  };

  const fetchMessages = async (userId) => {
    try {
      const response = await axios.get(`/api/webhook/chatbot/test/${userId}`);
      setMessages(response.data.messages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !user) return;

    setLoading(true);
    try {
      await axios.post('/api/webhook/chatbot/test', {
        user_id: user._id,
        message: newMessage
      });
      setNewMessage('');
      await fetchMessages(user._id);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConversation = async () => {
    if (!user) return;
    
    try {
      await axios.delete(`/api/webhook/chatbot/test/${user._id}`);
      setMessages([]); // Clear messages locally after successful deletion
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  return (
    <div className="chatbot-test-container">
      <div className="chatbot-header">
        <div className="chatbot-header-text">
          <p className="chatbot-intro">{t('chatbotTest.introduction')}</p>
          <h3>{t('chatbotTest.title')}</h3>
        </div>
        <button 
          onClick={handleDeleteConversation}
          disabled={!user || loading}
          className="delete-conversation-btn"
        >
          {t('chatbotTest.deleteConversation')}
        </button>
      </div>
      <div className="messages-container">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`message ${message.is_ai ? 'ai' : 'user'}`}
          >
            <div className="message-content">{message.content}</div>
            <div className="message-timestamp">
              {new Date(message.created_at).toLocaleTimeString()}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit} className="message-form">
        <div className="input-container-chatbottest">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder={t('chatbotTest.typePlaceholder')}
            disabled={loading}
          />
          <button type="submit" disabled={loading} className="send-button">
            <FaPaperPlane className={loading ? 'loading' : ''} />
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatbotTest;