import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaArrowLeft, FaBoxOpen, FaTruck, FaFileDownload, FaCalendar, FaBox, FaShippingFast, FaBarcode, FaBoxes } from 'react-icons/fa';
import './ReturnDetails.css';

const ReturnDetails = () => {
  const { returnId } = useParams();
  const navigate = useNavigate();
  const [returnDetails, setReturnDetails] = useState(null);
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isTrackingLoading, setIsTrackingLoading] = useState(false);

  useEffect(() => {
    const fetchReturnDetails = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get(`/api/webhook/returns/${returnId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setReturnDetails(response.data);
        
        // If tracking number exists, fetch tracking info from correct endpoint
        if (response.data.tracking_number) {
          setIsTrackingLoading(true);
          const trackingResponse = await axios.get(
            `/api/logistics/tracking/${response.data.tracking_number}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setTrackingInfo(trackingResponse.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
        setIsTrackingLoading(false);
      }
    };

    fetchReturnDetails();
  }, [returnId]);

  const formatDate = (isoString) => {
    return new Date(isoString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (error) return <div className="una-return-details-error">Error: {error}</div>;
  if (isLoading) {
    return (
      <div className="una-return-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-icons">
            <FaBoxOpen className="icon-primary" />
            <FaShippingFast className="icon-secondary" />
            <FaBarcode className="icon-tertiary" />
          </div>
          <h2>Loading Return Details</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaBoxOpen />
              <span>Processing Return Information</span>
            </div>
            <div className="una-metric-item">
              <FaTruck />
              <span>Fetching Shipping Details</span>
            </div>
            <div className="una-metric-item">
              <FaBoxes />
              <span>Loading Return Items</span>
            </div>
            {returnDetails?.tracking_number && (
              <div className="una-metric-item">
                <FaBarcode />
                <span>Retrieving Tracking Data</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  if (!returnDetails) return <div className="una-return-details-empty">Return not found</div>;

  return (
    <div className="una-return-container">
      <div className="una-return-panels">
        <div className="una-return-left-panel">
          <div className="una-return-nav">
            <button onClick={() => navigate(-1)} className="una-return-back-btn">
              <FaArrowLeft /> Back to Returns
            </button>
          </div>

          <div className="una-return-header">
            <div className="una-return-title">
              <h1>Return {returnId.substring(0, 8)}</h1>
              <div className="una-return-meta">
                <span className="una-return-date">
                  <FaCalendar /> {formatDate(returnDetails.created_at)}
                </span>
                <span className={`una-status-badge ${returnDetails.status.toLowerCase().replace(' ', '-')}`}>
                  {returnDetails.status}
                </span>
              </div>
            </div>
          </div>

          {returnDetails.summary && (
            <section className="una-return-section una-return-summary">
              <div className="una-return-section-header">
                <h2>Return Summary</h2>
              </div>
              <p className="una-return-text">{returnDetails.summary}</p>
            </section>
          )}
        </div>

        <div className="una-return-right-panel">
          <section className="una-return-section una-return-shipping">
            {returnDetails.tracking_number && (
              <>
                <div className="una-return-section-header">
                  <FaTruck />
                  <h2>Shipping Information</h2>
                </div>
                <div className="una-return-tracking-info">
                  <div className="una-return-tracking-number">
                    <span className="tracking-label">Tracking Number</span>
                    <span className="tracking-value una-return-text">{returnDetails.tracking_number}</span>
                  </div>
                  {returnDetails.label_url && (
                    <a 
                      href={returnDetails.label_url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="una-return-label-btn"
                    >
                      <FaFileDownload /> Download Shipping Label
                    </a>
                  )}
                  
                  {isTrackingLoading ? (
                    <div className="una-tracking-loading">Loading tracking information...</div>
                  ) : trackingInfo && (
                    <div className="una-tracking-details">
                      <div className="una-tracking-status">
                        <span className="tracking-status-label">Current Status:</span>
                        <span className={`tracking-status-value ${trackingInfo.status.toLowerCase().replace(' ', '-')}`}>
                          {trackingInfo.status}
                        </span>
                      </div>
                      <div className="una-tracking-timeline">
                        {trackingInfo.tracking_events.map((event, index) => (
                          <div key={index} className="una-tracking-event">
                            <div className="event-date">{formatDate(event.date)}</div>
                            <div className="event-location">{event.location}</div>
                            <div className="event-status">{event.status}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </section>

          <section className="una-return-section una-return-items">
            <div className="una-return-section-header">
              <FaBox />
              <h2>Items Being Returned</h2>
            </div>
            <div className="una-return-items-grid">
              {returnDetails.items.map((item, index) => (
                <div key={index} className="una-return-item-card">
                  <h3 className="una-return-text">{item.title}</h3>
                  <div className="una-return-item-stats">
                    <div className="una-return-item-stat">
                      <span className="stat-label">Quantity</span>
                      <span className="stat-value una-return-text">{item.quantity}</span>
                    </div>
                    <div className="una-return-item-stat">
                      <span className="stat-label">Price</span>
                      <span className="stat-value una-return-text">€{item.price}</span>
                    </div>
                    {item.weight && (
                      <div className="una-return-item-stat">
                        <span className="stat-label">Weight</span>
                        <span className="stat-value una-return-text">{item.weight}g</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ReturnDetails;