import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './ProductDetails.css';

const ProductDetails = () => {
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const { productId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get(`/api/shopify/get_product/${productId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProduct(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchProductDetails();
  }, [productId]);

  if (error) return <div className="una-product-error">Error: {error}</div>;
  if (!product) return <div className="una-product-loading-screen">Loading...</div>;

  return (
    <div className="una-product-details-container">
      <button className="una-back-button" onClick={() => navigate('/products')}>
        <FaArrowLeft /> Back to Products
      </button>
      
      <h1 className="una-product-title">{product.title}</h1>
      
      <div className="una-product-grid">
        <div className="una-product-image-container">
          {product.images.length > 0 && (
            <img 
              src={product.images[0].src}
              alt={product.title}
              className="una-product-image"
            />
          )}
        </div>

        <div className="una-product-info">
          <div className="una-product-meta">
            <div className="una-meta-item">
              <span className="una-meta-label">Vendor</span>
              <span className="una-meta-value">{product.vendor}</span>
            </div>
            <div className="una-meta-item">
              <span className="una-meta-label">Type</span>
              <span className="una-meta-value">{product.product_type}</span>
            </div>
            <div className="una-meta-item">
              <span className="una-meta-label">Status</span>
              <span className="una-meta-value">{product.status}</span>
            </div>
          </div>
          
          <div className="una-product-description">
            <h3>Description</h3>
            <div dangerouslySetInnerHTML={{ __html: product.body_html }} />
          </div>

          <div className="una-options-section">
            <h3>Available Options</h3>
            <div className="una-options-grid">
              {product.options.map(option => (
                <div key={option.name} className="una-option-group">
                  <h4>{option.name}</h4>
                  <div className="una-option-values">
                    {option.values.map((value, index) => (
                      <span key={index} className="una-option-value">
                        {value}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3>Variants</h3>
            <div className="una-variants-grid">
              {product.variants.map(variant => (
                <div key={variant.id} className="una-variant-card">
                  <h4>{variant.title}</h4>
                  <p className="una-variant-price">Price: ${variant.price}</p>
                  <p>SKU: {variant.sku}</p>
                  <p>Stock: {variant.inventory_quantity}</p>
                  {variant.compare_at_price && (
                    <p>Compare at: ${variant.compare_at_price}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;