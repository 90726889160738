import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Pie, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import {
  FaBoxOpen,
  FaWarehouse,
  FaChartBar,
  FaDollarSign,
  FaClock,
  FaChartLine, FaSmile, FaTicketAlt, FaRobot, 
  FaChartPie, FaTags
} from 'react-icons/fa';
import './SentimentStatistics.css';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const CHART_COLORS = {
  sentiment: {
    line: '#00ff9d', // neon green
    background: 'rgba(0, 255, 157, 0.1)',
  },
  resolved: {
    line: '#00ffff', // neon cyan
    background: 'rgba(0, 255, 255, 0.1)',
  },
  aiResolved: {
    line: '#ff00ff', // neon magenta
    background: 'rgba(255, 0, 255, 0.1)',
  }
};

const PIE_COLORS = [
  '#00ff9d', // neon green
  '#00ffff', // neon cyan
  '#ff00ff', // neon magenta
  '#8000ff', // neon purple
  '#ffff00', // neon yellow
  '#ff0000', // neon red
  '#0099ff', // neon blue
];

const UserStatisticsPanel = ({ userRole }) => {
  const { t } = useTranslation();

  // Simplified state - removed logistics stats
  const [sentimentStats, setSentimentStats] = useState(null);
  const [isSentimentLoading, setIsSentimentLoading] = useState(true);
  const [sentimentError, setSentimentError] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('7_days');
  const [tagsStats, setTagsStats] = useState(null);

  useEffect(() => {
    fetchSentimentStatistics();
    fetchTagsStatistics();
  }, []);

  const fetchSentimentStatistics = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/tickets/tickets/sentiment-statistics', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSentimentStats(response.data);
      setIsSentimentLoading(false);
    } catch (error) {
      console.error('Error fetching sentiment statistics:', error);
      setSentimentError(t('userStatistics.fetchError'));
      setIsSentimentLoading(false);
    }
  };

  const fetchTagsStatistics = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/tickets/tickets-tags-stats', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTagsStats(response.data);
    } catch (error) {
      console.error('Error fetching tags statistics:', error);
    }
  };

  if (sentimentError) return <div className="una-stats-error">{sentimentError}</div>;
  
  if (isSentimentLoading) {
    return (
      <div className="una-sentiment-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-icons">
            <FaChartLine className="icon-primary" />
            <FaSmile className="icon-secondary" />
            <FaTicketAlt className="icon-tertiary" />
          </div>
          <h2>{t('sentimentStatistics.loadingTitle', 'Analyzing Support Data')}</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaSmile />
              <span>{t('sentimentStatistics.loadingMetrics.sentiment', 'Processing Sentiment Analysis')}</span>
            </div>
            <div className="una-metric-item">
              <FaTicketAlt />
              <span>{t('sentimentStatistics.loadingMetrics.tickets', 'Calculating Ticket Statistics')}</span>
            </div>
            <div className="una-metric-item">
              <FaRobot />
              <span>{t('sentimentStatistics.loadingMetrics.ai', 'Processing AI Resolutions')}</span>
            </div>
            <div className="una-metric-item">
              <FaTags />
              <span>{t('sentimentStatistics.loadingMetrics.tags', 'Analyzing Tags Distribution')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Prepare data for sentiment statistics chart
  const sentimentData = sentimentStats?.sentiment_statistics[selectedPeriod];
  let sentimentChartData = null;
  if (sentimentData) {
    const labels = sentimentData.raw.map((item) => item._id);
    const averageSentiments = sentimentData.raw.map(
      (item) => item.average_sentiment
    );

    sentimentChartData = {
      labels: labels,
      datasets: [{
        label: t('sentimentStatistics.averageSentiment'),
        data: averageSentiments,
        borderColor: CHART_COLORS.sentiment.line,
        backgroundColor: CHART_COLORS.sentiment.background,
        borderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6,
        tension: 0.4,
        fill: true
      }]
    };
  }

  // Prepare data for resolved tickets statistics chart
  const resolvedData = sentimentStats?.resolved_statistics[selectedPeriod];
  let resolvedChartData = null;
  if (resolvedData) {
    const labels = resolvedData.raw.map((item) => item._id);
    const resolvedCounts = resolvedData.raw.map((item) => item.resolved);
    const aiResolvedCounts = resolvedData.raw.map((item) => item.ai_resolved);

    resolvedChartData = {
      labels: labels,
      datasets: [
        {
          label: t('sentimentStatistics.resolvedTickets'),
          data: resolvedCounts,
          borderColor: CHART_COLORS.resolved.line,
          backgroundColor: CHART_COLORS.resolved.background,
          borderWidth: 2,
          pointRadius: 4,
          pointHoverRadius: 6,
          tension: 0.4,
          fill: true
        },
        {
          label: t('sentimentStatistics.aiResolvedTickets'),
          data: aiResolvedCounts,
          borderColor: CHART_COLORS.aiResolved.line,
          backgroundColor: CHART_COLORS.aiResolved.background,
          borderWidth: 2,
          pointRadius: 4,
          pointHoverRadius: 6,
          tension: 0.4,
          fill: true
        }
      ]
    };
  }

  // Add tags chart data preparation
  let tagsChartData = null;
  if (tagsStats?.tags_statistics) {
    tagsChartData = {
      labels: tagsStats.tags_statistics.map(item => item._id),
      datasets: [{
        data: tagsStats.tags_statistics.map(item => item.percentage),
        backgroundColor: PIE_COLORS,
      }],
    };
  }

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      legend: {
        labels: {
          color: '#fff',
          padding: 20,
          font: {
            size: 13,
            family: "'Roboto', sans-serif"
          },
          usePointStyle: true,
          filter: function(item) {
            return !item.text.includes('Background');
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(13, 14, 26, 0.95)',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 12,
        boxPadding: 8,
        cornerRadius: 8,
        displayColors: true,
        borderColor: 'rgba(0, 255, 157, 0.1)',
        borderWidth: 1,
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
          maxRotation: 45,
          minRotation: 45
        },
        border: {
          display: false
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.03)',
          drawBorder: false,
          lineWidth: 1
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
          padding: 10,
          maxTicksLimit: 5
        },
        border: {
          display: false
        },
        beginAtZero: true
      }
    }
  };

  return (
    <div className="user-statistics-panel">
      {sentimentStats && (
        <>
          <div className="period-selector">
            {['7_days', '14_days', '30_days'].map((period) => (
              <button
                key={period}
                className={selectedPeriod === period ? 'active' : ''}
                onClick={() => setSelectedPeriod(period)}
              >
                {t(`sentimentStatistics.period.${period}`)}
              </button>
            ))}
          </div>

          <div className="charts-container">
            <div className="line-charts">
              {sentimentChartData && (
                <div className="sentiment-statistics-section">
                  <h3>{t('sentimentStatistics.sentimentTitle')}</h3>
                  <div className="chart-container">
                    <Line data={sentimentChartData} options={chartOptions} />
                  </div>
                </div>
              )}

              {resolvedChartData && (
                <div className="resolved-statistics-section">
                  <h3>{t('sentimentStatistics.resolvedTitle')}</h3>
                  <div className="chart-container">
                    <Line data={resolvedChartData} options={chartOptions} />
                  </div>
                </div>
              )}
            </div>

            {tagsChartData && (
              <div className="tags-statistics-section">
                <h3>{t('sentimentStatistics.tagsTitle')}</h3>
                <div className="pie-chart-container">
                  <Pie
                    data={tagsChartData}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'right',
                          labels: {
                            boxWidth: 12,
                          },
                        },
                        tooltip: {
                          callbacks: {
                            label: (context) => {
                              const label = context.label || '';
                              const value = context.raw || 0;
                              return `${label}: ${value.toFixed(1)}%`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserStatisticsPanel;