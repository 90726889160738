import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaBox, FaTruck, FaUser, FaMapMarkerAlt, FaFileInvoice, FaCreditCard, FaShippingFast } from 'react-icons/fa';
import './OrderDetails.css';

const OrderDetails = () => {
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundNote, setRefundNote] = useState('');
  const [notifyCustomer, setNotifyCustomer] = useState(true);
  const [refundError, setRefundError] = useState(null);
  const { orderId } = useParams();
  const navigate = useNavigate();

  const fetchOrderDetails = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get(`/api/shopify/get_order/${orderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrder(response.data);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [orderId]);

  const handleRefund = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const lineItems = Object.entries(selectedItems)
        .filter(([_, quantity]) => quantity > 0)
        .map(([id, quantity]) => ({
          line_item_id: id,
          quantity: quantity,
          restock_type: "NO_RESTOCK"
        }));

      if (lineItems.length === 0) {
        setRefundError('Please select items to refund');
        return;
      }

      const refundData = {
        order_id: orderId,
        line_items: lineItems,
        note: refundNote,
        notify_customer: notifyCustomer
      };

      await axios.post('/api/shopify/create_refund', refundData, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setShowRefundModal(false);
      setSelectedItems({});
      // Now fetchOrderDetails is in scope
      fetchOrderDetails();
    } catch (err) {
      setRefundError(err.response?.data?.detail || err.message);
    }
  };

  const renderItemsList = () => (
    <div className="una-items-list">
      {order.line_items.map((item) => (
        <div key={item.id} className="una-item">
          <div className="una-item-details">
            <input
              type="checkbox"
              checked={selectedItems[item.id] > 0}
              onChange={(e) => {
                setSelectedItems({
                  ...selectedItems,
                  [item.id]: e.target.checked ? item.quantity : 0
                });
              }}
            />
            <h4>{item.title}</h4>
            <p>SKU: {item.sku}</p>
            {selectedItems[item.id] > 0 && (
              <input
                type="number"
                min="1"
                max={item.quantity}
                value={selectedItems[item.id]}
                onChange={(e) => {
                  setSelectedItems({
                    ...selectedItems,
                    [item.id]: parseInt(e.target.value) || 0
                  });
                }}
              />
            )}
            <p>Quantity: {item.quantity}</p>
            {item.total_discount > 0 && (
              <p className="una-discount">Discount: ${item.total_discount}</p>
            )}
          </div>
          <div className="una-item-price">
            ${item.price}
          </div>
        </div>
      ))}
    </div>
  );

  const renderRefundModal = () => (
    showRefundModal && (
      <div className="una-modal-overlay">
        <div className="una-modal">
          <h2>Create Refund</h2>
          {refundError && <div className="una-error">{refundError}</div>}
          <div className="una-modal-content">
            <textarea
              placeholder="Refund note (optional)"
              value={refundNote}
              onChange={(e) => setRefundNote(e.target.value)}
            />
            <label>
              <input
                type="checkbox"
                checked={notifyCustomer}
                onChange={(e) => setNotifyCustomer(e.target.checked)}
              />
              Notify customer
            </label>
          </div>
          <div className="una-modal-actions">
            <button onClick={() => setShowRefundModal(false)}>Cancel</button>
            <button onClick={handleRefund}>Confirm Refund</button>
          </div>
        </div>
      </div>
    )
  );

  if (error) return <div className="una-order-error">Error: {error}</div>;
  if (!order) {
    return (
      <div className="una-order-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-icons">
            <FaFileInvoice className="icon-primary" />
            <FaShippingFast className="icon-secondary" />
            <FaCreditCard className="icon-tertiary" />
          </div>
          <h2>Loading Order Details</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaFileInvoice />
              <span>Loading Order Information</span>
            </div>
            <div className="una-metric-item">
              <FaBox />
              <span>Processing Order Items</span>
            </div>
            <div className="una-metric-item">
              <FaTruck />
              <span>Fetching Shipping Details</span>
            </div>
            <div className="una-metric-item">
              <FaCreditCard />
              <span>Verifying Payment Status</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-order-details-container">
      <button className="una-back-button" onClick={() => navigate('/orders')}>
        <FaArrowLeft /> Back to Orders
      </button>

      <div className="una-order-header">
        <h1>Order #{order.order_number}</h1>
        <div className="una-order-status-badges">
          <span className={`una-status-badge ${order.financial_status}`}>
            {order.financial_status}
          </span>
          <span className={`una-status-badge ${order.fulfillment_status || 'unfulfilled'}`}>
            {order.fulfillment_status || 'Unfulfilled'}
          </span>
        </div>
      </div>

      <div className="una-order-grid">
        <div className="una-order-summary">
          <div className="una-summary-card">
            <h3><FaUser /> Customer Details</h3>
            <p>{order.customer.name || 'Guest Customer'}</p>
            <p>{order.customer.email}</p>
          </div>

          {order.shipping_address && (
            <div className="una-summary-card">
              <h3><FaMapMarkerAlt /> Shipping Address</h3>
              <p>{order.shipping_address.name}</p>
              <p>{order.shipping_address.address1}</p>
              <p>{order.shipping_address.city}, {order.shipping_address.province_code}</p>
              <p>{order.shipping_address.country}</p>
              <p>{order.shipping_address.zip}</p>
            </div>
          )}

          {order.shipping_lines.length > 0 && (
            <div className="una-summary-card">
              <h3><FaTruck /> Shipping Method</h3>
              {order.shipping_lines.map((line, index) => (
                <div key={index}>
                  <p>{line.title}</p>
                  <p className="una-price">${line.price}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="una-order-items">
          <div className="una-items-card">
            <div className="una-items-header">
              <h3><FaBox /> Order Items</h3>
              <button 
                className="una-refund-button"
                onClick={() => setShowRefundModal(true)}
                disabled={Object.values(selectedItems).every(v => v === 0)}
              >
                Create Refund
              </button>
            </div>
            {renderItemsList()}

            <div className="una-order-totals">
              <div className="una-total-line">
                <span>Subtotal</span>
                <span>${order.subtotal_price}</span>
              </div>
              {order.total_tax > 0 && (
                <div className="una-total-line">
                  <span>Tax</span>
                  <span>${order.total_tax}</span>
                </div>
              )}
              <div className="una-total-line total">
                <span>Total</span>
                <span>${order.total_price}</span>
              </div>
            </div>
          </div>

          {order.note && (
            <div className="una-note-card">
              <h3>Order Notes</h3>
              <p>{order.note}</p>
            </div>
          )}

          {order.cancelled_at && (
            <div className="una-cancelled-card">
              <h3>Cancellation Details</h3>
              <p>Cancelled at: {new Date(order.cancelled_at).toLocaleString()}</p>
              {order.cancel_reason && <p>Reason: {order.cancel_reason}</p>}
            </div>
          )}
        </div>
      </div>
      {renderRefundModal()}
    </div>
  );
};

export default OrderDetails;