import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TicketTableDashboard from './TicketTableDashboard';
import { useTranslation } from 'react-i18next';
import { FaTicketAlt, FaRobot, FaExclamationCircle } from 'react-icons/fa';
import './Tickets.css';

const Tickets = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [tickets, setTickets] = useState([]);
    const [ticketStats, setTicketStats] = useState({
        remaining_tickets: 0,
        automated_tickets: 0,
        pending_tickets: 0
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const token = sessionStorage.getItem('access_token');
        if (!token) {
            navigate('/');
            return;
        }

        const fetchData = async () => {
            try {
                const [ticketsResponse, statsResponse] = await Promise.all([
                    axios.get('/api/tickets/tickets', {
                        headers: { Authorization: `Bearer ${token}` },
                        params: { page: 1, limit: 500 }
                    }),
                    axios.get('/api/tickets/stats', {
                        headers: { Authorization: `Bearer ${token}` }
                    })
                ]);

                setTickets(ticketsResponse.data.tickets || []);
                setTicketStats(statsResponse.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setError(error.message || 'An error occurred while fetching data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate]);

    const handleTicketClick = (ticketId) => {
        navigate(`/tickets/${ticketId}/messages`);
    };

    if (loading) return <div className="una-loading">Loading...</div>;
    if (error) return <div className="una-error">{error}</div>;

    return (
        <div className="una-tickets-container">
            {/* Stats Cards */}
            <div className="una-tickets-stats">
                <div className="una-stat-card">
                    <div className="una-stat-icon">
                        <FaTicketAlt />
                    </div>
                    <div className="una-stat-content">
                        <span className="una-stat-value">{ticketStats.remaining_tickets}</span>
                        <span className="una-stat-label">{t('remainingTickets')}</span>
                    </div>
                </div>

                <div className="una-stat-card">
                    <div className="una-stat-icon automated">
                        <FaRobot />
                    </div>
                    <div className="una-stat-content">
                        <span className="una-stat-value">{ticketStats.automated_tickets}</span>
                        <span className="una-stat-label">{t('automatedTickets')}</span>
                    </div>
                </div>

                <div className="una-stat-card">
                    <div className="una-stat-icon pending">
                        <FaExclamationCircle />
                    </div>
                    <div className="una-stat-content">
                        <span className="una-stat-value">{ticketStats.pending_tickets}</span>
                        <span className="una-stat-label">{t('pendingTickets')}</span>
                    </div>
                </div>
            </div>

            {/* Tickets Table */}
            <div className="una-tickets-table">
                <TicketTableDashboard 
                    tickets={tickets}
                    onTicketClick={handleTicketClick}
                />
            </div>
        </div>
    );
};

export default Tickets;