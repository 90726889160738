import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CreateAgent.css';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaUserPlus, FaEdit, FaTrash, FaTimes, FaUsers, FaUsersCog, FaUserShield } from 'react-icons/fa';

const CreateAgent = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [agents, setAgents] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [newDescription, setNewDescription] = useState('');
  const [employeeStats, setEmployeeStats] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    const fetchData = async () => {
      await fetchUserData(token);
      await fetchAgents(token);
    };

    fetchData();
  }, [navigate]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null);
    }
  };

  const fetchEmployeeStats = async (token, employeeId) => {
    try {
      const response = await axios.get(`/api/tickets/employee-stats/${employeeId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setEmployeeStats(prev => ({
        ...prev,
        [employeeId]: response.data
      }));
    } catch (error) {
      console.error(`Failed to fetch stats for employee ${employeeId}:`, error);
    }
  };

  const fetchAgents = async (token) => {
    try {
      const response = await axios.get('/api/employees/agents', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAgents(response.data);
      
      response.data.forEach(agent => {
        fetchEmployeeStats(token, agent._id);
      });
    } catch (error) {
      console.error('Failed to fetch agents:', error);
    }
  };

  const handleCreateAgent = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post(
        '/api/employees/create-agent',
        { name, email, description },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setName('');
      setEmail('');
      setDescription('');
      setCreateModalOpen(false);
      fetchAgents(token);
      alert('Agent created successfully');
    } catch (error) {
      console.error('Failed to create agent:', error);
    }
  };

  const handleEditDescription = (agent) => {
    setCurrentAgent(agent);
    setNewDescription(agent.description);
    setEditModalOpen(true);
  };

  const handleDeleteAgent = async (agentEmail) => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`/api/employees/delete-employee/${agentEmail}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchAgents(token);
      alert('Agent deleted successfully');
    } catch (error) {
      console.error('Failed to delete agent:', error);
    }
  };

  const handleUpdateDescription = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        '/api/employees/update-description',
        { email: currentAgent.email, description: newDescription },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setEditModalOpen(false);
      fetchAgents(token);
    } catch (error) {
      console.error('Failed to update description:', error);
    }
  };

  if (user === undefined) {
    return (
      <div className="una-employee-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-icons">
            <FaUsers className="icon-primary" />
            <FaUsersCog className="icon-secondary" />
            <FaUserShield className="icon-tertiary" />
          </div>
          <h2>{t('createAgent.loadingEmployees')}</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaUsers />
              <span>{t('createAgent.loadingEmployeeData')}</span>
            </div>
            <div className="una-metric-item">
              <FaUsersCog />
              <span>{t('createAgent.processingPermissions')}</span>
            </div>
            <div className="una-metric-item">
              <FaUserShield />
              <span>{t('createAgent.loadingRoles')}</span>
            </div>
            <div className="una-metric-item">
              <FaUserPlus />
              <span>{t('createAgent.preparingDashboard')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (user.subscription_plan === 'free') {
    return (
      <div className="create-agent-container">
        <h2 className="page-title">{t('createAgent.manageEmployees')}</h2>
        <div className="not-accessible">
          <p>{t('createAgent.notAccessible')}</p>
          <Link to="/billing" className="upgrade-link">{t('createAgent.upgrade')}</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="employee-management-container">
      <div className="employee-dashboard-card">
        <h2 className="employee-section-title">
          <FaUserPlus className="employee-section-icon" />
          {t('createAgent.manageEmployees')}
        </h2>

        <button className="employee-add-button" onClick={() => setCreateModalOpen(true)}>
          <FaUserPlus /> {t('createAgent.createEmployee')}
        </button>

        <div className="employee-grid">
          {agents.map((agent) => (
            <div key={agent._id} className="employee-card">
              <div className="employee-header">
                <div className="employee-info">
                  <div className="employee-name">{agent.name}</div>
                  <div className="employee-email">{agent.email}</div>
                </div>
                <div className="employee-actions">
                  <button 
                    className="employee-action-button edit"
                    onClick={() => handleEditDescription(agent)}
                    aria-label={t('createAgent.editEmployee')}
                  >
                    <FaEdit />
                  </button>
                  <button 
                    className="employee-action-button delete"
                    onClick={() => handleDeleteAgent(agent.email)}
                    aria-label={t('createAgent.deleteEmployee')}
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
              <div className="employee-description">
                {agent.description}
              </div>
              {employeeStats[agent._id] && (
                <div className="employee-stats">
                  <div className="stats-row">
                    <div className="stat-item">
                      <span className="stat-label">{t('createAgent.totalTickets')}</span>
                      <span className="stat-value">{employeeStats[agent._id].ticket_stats.total_assigned}</span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-label">{t('createAgent.activeTickets')}</span>
                      <span className="stat-value">{employeeStats[agent._id].ticket_stats.active_tickets}</span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-label">{t('createAgent.resolutionRate')}</span>
                      <span className="stat-value">{employeeStats[agent._id].ticket_stats.resolution_rate}%</span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-label">{t('createAgent.avgResolutionTime')}</span>
                      <span className="stat-value">
                        {employeeStats[agent._id].ticket_stats.avg_resolution_time_hours.toFixed(1)}h
                      </span>
                    </div>
                  </div>
                  
                  {employeeStats[agent._id].tags_stats.tags_statistics.length > 0 && (
                    <div className="tags-stats">
                      <span className="stat-label">{t('createAgent.topTags')}</span>
                      <div className="tags-list">
                        {employeeStats[agent._id].tags_stats.tags_statistics
                          .slice(0, 3)
                          .map(tag => (
                            <span key={tag._id} className="tag">
                              {tag._id} ({tag.percentage}%)
                            </span>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Create Modal */}
        {createModalOpen && (
          <div className="employee-create-modal">
            <div className="employee-modal-content">
              <h3>{t('createAgent.createEmployee')}</h3>
              <form onSubmit={handleCreateAgent} className="employee-create-form">
                <div className="employee-form-group">
                  <label htmlFor="name">{t('createAgent.name')}</label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="employee-form-group">
                  <label htmlFor="email">{t('createAgent.email')}</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="employee-form-group">
                  <label htmlFor="description">{t('createAgent.description')}</label>
                  <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    rows="4"
                  ></textarea>
                </div>
                <div className="employee-modal-actions">
                  <button type="button" className="employee-cancel-button" onClick={() => setCreateModalOpen(false)}>
                    {t('createAgent.cancel')}
                  </button>
                  <button type="submit" className="employee-submit-button">
                    {t('createAgent.create')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateAgent;