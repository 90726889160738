import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './MessageWindow.css';
import { useNavigate, useParams } from 'react-router-dom';
import InfoPanel from './InfoPanel';
import { useTranslation } from 'react-i18next';
import { FaPaperPlane, FaLightbulb, FaCheck, FaTimes, FaLanguage, FaTruck, FaUser, FaRobot, FaEllipsisV, FaEdit } from 'react-icons/fa';
import WMSChat from './WMSChat';
import CarriersConversation from './CarriersConversation';

const MessageWindow = () => {
    const { t } = useTranslation();
    const { ticketId } = useParams();
    const [ticket, setTicket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [employee, setEmployee] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [editingMessage, setEditingMessage] = useState(null);
    const [editedContent, setEditedContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [userLoading, setUserLoading] = useState(true);
    const [targetLanguage, setTargetLanguage] = useState('original');
    const [translatedMessages, setTranslatedMessages] = useState({});
    const [customerLoading, setCustomerLoading] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);
    const navigate = useNavigate();
    const [overlayState, setOverlayState] = useState({ show: false, message: '', success: false });
    const [user, setUser] = useState(null);
    const [clientInfo, setClientInfo] = useState('');
    const [orderDetails, setOrderDetails] = useState('');
    const [summary, setSummary] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isTranslating, setIsTranslating] = useState(false);
    const [carriersConversation, setCarriersConversation] = useState(null);
    const [continuationMessage, setContinuationMessage] = useState('');
    const [testUserId, setTestUserId] = useState('');
    const [testThreadId, setTestThreadId] = useState('');
    const [testAssistantId, setTestAssistantId] = useState('');
    const [validatingMessage, setValidatingMessage] = useState(false);
    const [activeTab, setActiveTab] = useState('carriers');
    const [showActionMenu, setShowActionMenu] = useState(false);
    const [showLanguageMenu, setShowLanguageMenu] = useState(false);
    const actionMenuRef = useRef(null);
    

    const wsRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
                setShowActionMenu(false);
                setShowLanguageMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const fetchCarriersConversation = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get(`/api/tickets/carriers_conversation/${ticketId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCarriersConversation(response.data);
        } catch (error) {
            console.error('Failed to fetch carriers conversation:', error);
        }
    };

    const connectWebSocket = () => {
        const token = sessionStorage.getItem('access_token');
        if (!token) return;

        wsRef.current = new WebSocket(`/api/ws?token=${token}`);
        
        wsRef.current.onopen = (event) => {
            console.log('WebSocket connection established:', event);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }
        };

        wsRef.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        wsRef.current.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
        };

        wsRef.current.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
            if (event.data === 'ping') {
                wsRef.current.send('pong');
            } else {
                // Fetch messages only to quickly update the message list
                fetchMessages();
            }
        };
    };

    useEffect(() => {
        fetchUserData();
        fetchTicketAndMessages();
        fetchCarriersConversation();
        connectWebSocket();

        const pingInterval = setInterval(() => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send('ping');
            }
        }, 30000);

        return () => {
            clearInterval(pingInterval);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [ticketId]);

    const fetchUserData = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get('/api/users/user', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        } finally {
            setUserLoading(false);
        }
    };

    const fetchTicketAndMessages = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get(`/api/tickets/${ticketId}/messages`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTicket(response.data.ticket);
            setMessages(response.data.messages);
            setEmployee(response.data.employee);

            // Fetch order and customer info asynchronously
            fetchOrderAndCustomerInfo(response.data.ticket, token);

            setSummary(response.data.ticket.summary || 'Summary information will be fetched/updated here.');
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch ticket and messages:', error);
            setLoading(false);
        }
    };

    const fetchMessages = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get(`/api/tickets/${ticketId}/messages`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessages(response.data.messages);
        } catch (error) {
            console.error('Failed to fetch messages:', error);
        }
    };

    const fetchOrderAndCustomerInfo = async (ticketData, token) => {
        if (ticketData.customer_id) {
            fetchCustomerInfo(ticketData.customer_id, token);
        } else {
            setClientInfo('');
        }

        if (ticketData.order) {
            fetchOrderInfo(ticketData.order, token);
        } else {
            setOrderDetails('');
        }
    };

    const formatMessageContent = (content) => {
        const lines = content.split('\n');
        return lines.map((line, index) => {
            // Regular expression to match URLs
            const urlRegex = /(https?:\/\/[^\s]+\.pdf)/gi;
            const parts = line.split(urlRegex);
            
            return (
                <span key={index}>
                    {parts.map((part, partIndex) => {
                        if (part.match(urlRegex)) {
                            // Convert http to https
                            const secureUrl = part.replace('http://', 'https://');
                            return (
                                <div key={partIndex}>
                                    <object
                                        data={secureUrl}
                                        type="application/pdf"
                                        width="100%"
                                        height="500px"
                                    >
                                        <p>Unable to display PDF. <a href={secureUrl}>Download PDF</a></p>
                                    </object>
                                </div>
                            );
                        }
                        return part;
                    })}
                    <br />
                </span>
            );
        });
    };
    
    const fetchCustomerInfo = async (customerId, token) => {
        setCustomerLoading(true);
        try {
            const response = await axios.get(`/api/shopify/get_customer`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { customer_id: customerId }
            });
            const customer = response.data;
            setClientInfo(`Phone: ${customer.phone}\nFirst Name: ${customer.first_name}\nLast Name: ${customer.last_name}\nEmail: ${customer.email}`);
        } catch (error) {
            console.error('Failed to fetch customer info:', error);
        } finally {
            setCustomerLoading(false);
        }
    };

    const fetchOrderInfo = async (orderId, token) => {
        setOrderLoading(true);
        try {
            const response = await axios.get(`/api/logistics/edn/order_by_id/${orderId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const order = response.data;
            const formattedOrderDetails = `
Order ID: ${order.order_id}
Reference: ${order.reference}
Status: ${order.status}
Total Price: ${order.grand_total} ${order.currency}
Shipping Cost: ${order.shipping_cost} ${order.currency}
Created At: ${order.created_at}
Customer Email: ${order.customer_email}
Customer Name: ${order.customer_name}
Customer Phone: ${order.customer_phone}
Shipping Address:
    ${order.shipping_address.firstname} ${order.shipping_address.lastname}
    ${order.shipping_address.street1}
    ${order.shipping_address.street2 ? order.shipping_address.street2 + '\n' : ''}
    ${order.shipping_address.postcode} ${order.shipping_address.city}
    ${order.shipping_address.country}
Line Items:
${order.items.map(item => `
Product ID: ${item.product_id}
Name: ${item.name}
SKU: ${item.sku}
Quantity: ${item.quantity}
Price: ${item.price} ${order.currency}
EAN: ${item.ean || 'N/A'}
`).join('\n')}
`;
            setOrderDetails(formattedOrderDetails);
        } catch (error) {
            console.error('Failed to fetch order info:', error);
        } finally {
            setOrderLoading(false);
        }
    };

    const handleSendMessage = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post('/api/messages/message/add', {
                ticket_id: ticketId,
                content: newMessage,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setNewMessage('');
            fetchMessages(); // Fetch messages only to update the message list quickly
        } catch (error) {
            console.error('Failed to send message:', error);
        }
    };

    const handleGetAssistantResponse = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const aiLogsString = JSON.stringify(ticket.ai_logs);

            const response = await axios.post('/api/messages/writer_assistant', {
                text: newMessage,
                ai_logs: aiLogsString,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setNewMessage(response.data.response);
        } catch (error) {
            console.error('Failed to get assistant response:', error);
        }
    };

    const handleResolveTicket = async () => {
        setOverlayState({ show: true, message: t('messageWindow.processing'), success: false });
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(`/api/tickets/${ticketId}/resolve`, null, {
                headers: { Authorization: `Bearer ${token}` },
            });
            await fetchTicketAndMessages(); // Fetch ticket and messages to update the status and messages
            setOverlayState({ show: true, message: t('messageWindow.resolveSuccess'), success: true });
        } catch (error) {
            console.error('Failed to resolve ticket:', error);
            setOverlayState({ show: true, message: t('messageWindow.resolveError'), success: false });
        } finally {
            setTimeout(() => setOverlayState({ show: false, message: '', success: false }), 2000);
        }
    };
    
    const handleCloseTicket = async () => {
        setOverlayState({ show: true, message: t('messageWindow.processing'), success: false });
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(`/api/tickets/${ticketId}/close`, null, {
                headers: { Authorization: `Bearer ${token}` },
            });
            await fetchTicketAndMessages(); // Fetch ticket and messages to update the status and messages
            setOverlayState({ show: true, message: t('messageWindow.closeSuccess'), success: true });
        } catch (error) {
            console.error('Failed to close ticket:', error);
            setOverlayState({ show: true, message: t('messageWindow.closeError'), success: false });
        } finally {
            setTimeout(() => setOverlayState({ show: false, message: '', success: false }), 2000);
        }
    };

    const handleSendDraftMessage = async (messageId) => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post(`/api/messages/send`, { message_id: messageId }, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            });
            fetchMessages(); // Fetch messages to update the message list
        } catch (error) {
            console.error('Failed to send draft message:', error);
        }
    };

    const handleDeleteDraftMessage = async (messageId) => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post(`/api/messages/delete`, { message_id: messageId }, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            });
            fetchMessages(); // Fetch messages to update the message list
        } catch (error) {
            console.error('Failed to delete draft message:', error);
        }
    };

    const handleModifyDraftMessage = (message) => {
        setEditingMessage(message);
        setEditedContent(message.content);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleSaveModifiedMessage = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post(`/api/messages/edit`, {
                message_id: editingMessage._id,
                content: editedContent,
            }, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            });
            setEditingMessage(null);
            setEditedContent('');
            fetchMessages(); // Fetch messages to update the message list
        } catch (error) {
            console.error('Failed to modify draft message:', error);
        }
    };

    const handleCancelModification = () => {
        setEditingMessage(null);
        setEditedContent('');
    };

    useEffect(() => {
        if (!userLoading && !user) {
            navigate('/');
        }
    }, [userLoading, user, navigate]);

    if (loading) {
        return <div>{t('messageWindow.loading')}</div>;
    }

    if (!ticket) {
        return <div>{t('messageWindow.failedToLoadTicket')}</div>;
    }

    const isEditable = !['resolved', 'closed', 'ai_resolved'].includes(ticket.status);
    
    const handleLanguageChange = async (language) => {
        setTargetLanguage(language);
        if (language !== 'original') {
            setIsTranslating(true);
            await translateMessages(language);
            setIsTranslating(false);
        } else {
            setTranslatedMessages({});
        }
    };

    const translateMessages = async (language) => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.post('/api/messages/translate_messages', {
                messages: messages.map(m => ({ id: m._id, content: m.content })),
                target_language: language
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const translatedObj = {};
            response.data.translated_messages.forEach(m => {
                translatedObj[m.id] = m.content;
            });
            setTranslatedMessages(translatedObj);
        } catch (error) {
            console.error('Failed to translate messages:', error);
        }
    };

    const renderMessageContent = (message) => {
        const content = targetLanguage === 'original' ? message.content : (translatedMessages[message._id] || message.content);
        return formatMessageContent(content);
    };

    const renderMessage = (message) => {
        const isSent = message.sender === 'employee' || message.status === 'sent' || message.status === 'draft';
        const messageClass = `message ${isSent ? 'sent' : 'received'} ${message.status}`;
        
        return (
            <div key={message._id} className={messageClass}>
                {message.status === 'automated' && (
                    <span className="message-status-badge automated">{t('messageWindow.automated')}</span>
                )}
                <div className="message-content">
                    {renderMessageContent(message)}
                </div>
                <div className="message-meta">
                    <span className="message-status">
                        {message.status 
                            ? t(`messageWindow.statusOptions.${message.status}`)
                            : t('messageWindow.statusOptions.received')}
                    </span>
                    <span className="message-timestamp">{formatDate(message.created_at)}</span>
                </div>
                {message.status === 'draft' && isEditable && (
                    <div className="draft-actions">
                        <button className="draft-message-button send" onClick={() => handleSendDraftMessage(message._id)}>
                            <FaPaperPlane /> {t('messageWindow.send')}
                        </button>
                        <button className="draft-message-button delete" onClick={() => handleDeleteDraftMessage(message._id)}>
                            <FaTimes /> {t('messageWindow.delete')}
                        </button>
                        <button className="draft-message-button modify" onClick={() => handleModifyDraftMessage(message)}>
                            <FaEdit /> {t('messageWindow.modify')}
                        </button>
                    </div>
                )}
            </div>
        );
    };

    const handleValidateCarrierMessage = async (conversationId, approved) => {
        setValidatingMessage(true);
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.post('/api/logistics/validate-carrier-message', 
                {
                    conversation_id: conversationId,
                    approved: approved
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            // Refresh the carriers conversation after validation
            await fetchCarriersConversation();
            
            setOverlayState({ 
                show: true, 
                message: approved ? t('messageWindow.messageApproved') : t('messageWindow.messageRejected'), 
                success: true 
            });
        } catch (error) {
            console.error('Failed to validate carrier message:', error);
            setOverlayState({ 
                show: true, 
                message: t('messageWindow.validationError'), 
                success: false 
            });
        } finally {
            setValidatingMessage(false);
            setTimeout(() => setOverlayState({ show: false, message: '', success: false }), 2000);
        }
    };

    return (
        <div className="app-container">
            <div className="message-window-layout">
                <div className="ticket-header">
                    <h2>{ticket.subject}</h2>
                    <div className="ticket-meta">
                        <span className={`ticket-status ${ticket.status}`}>
                            {t(`messageWindow.statusOptions.${ticket.status}`, ticket.status)}
                        </span>
                        <span className="ticket-employee">
                            {t('messageWindow.employee')}: {employee}
                        </span>
                        <div className="action-menu-container" ref={actionMenuRef}>
                            <button 
                                className="action-menu-trigger"
                                onClick={() => setShowActionMenu(!showActionMenu)}
                            >
                                <FaEllipsisV />
                            </button>
                            {showActionMenu && (
                                <div className="action-menu">
                                    {isEditable && (
                                        <>
                                            <button onClick={handleResolveTicket}>
                                                <FaCheck /> {t('messageWindow.resolveTicket')}
                                            </button>
                                            <button onClick={handleCloseTicket}>
                                                <FaTimes /> {t('messageWindow.closeTicket')}
                                            </button>
                                        </>
                                    )}
                                    <div className="action-menu-item" 
                                         onMouseEnter={() => setShowLanguageMenu(true)}
                                         onMouseLeave={() => setShowLanguageMenu(false)}>
                                        <span>
                                            <FaLanguage /> {t('messageWindow.translate')}
                                        </span>
                                        {showLanguageMenu && (
                                            <div className="language-submenu">
                                                <button onClick={() => handleLanguageChange('original')}>
                                                    {t('messageWindow.original')}
                                                </button>
                                                <button onClick={() => handleLanguageChange('en')}>English</button>
                                                <button onClick={() => handleLanguageChange('fr')}>Français</button>
                                                <button onClick={() => handleLanguageChange('es')}>Español</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                
                <div className="message-window-content">
                    <div className="message-window-messages">
                        {messages.map((message) => renderMessage(message))}
                    </div>

                    {isEditable && (
                        <div className="input-container">
                            <textarea
                                value={newMessage}
                                onChange={(e) => {
                                    setNewMessage(e.target.value);
                                    // Auto-grow functionality
                                    e.target.style.height = '40px';
                                    e.target.style.height = `${Math.min(e.target.scrollHeight, 120)}px`;
                                }}
                                placeholder={t('messageWindow.typeYourResponse')}
                                className="message-window-textarea"
                                rows={1}
                            />
                            <div className="response-actions">
                                <button className="message-window-action-button message-window-send" onClick={handleSendMessage}>
                                    <FaPaperPlane /> {t('messageWindow.send')}
                                </button>
                                <button className="message-window-action-button message-window-ai-help" onClick={handleGetAssistantResponse}>
                                    <FaLightbulb /> {t('messageWindow.aiHelp')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="side-panel">
                <div className="tab-buttons">
                    <button 
                        className={`tab-button ${activeTab === 'carriers' ? 'active' : ''}`}
                        onClick={() => setActiveTab('carriers')}
                        title={t('messageWindow.carriersConversation')}
                    >
                        <FaTruck />
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'info' ? 'active' : ''}`}
                        onClick={() => setActiveTab('info')}
                        title={t('messageWindow.customerInfo')}
                    >
                        <FaUser />
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'wms' ? 'active' : ''}`}
                        onClick={() => setActiveTab('wms')}
                        title={t('messageWindow.wmsAssistant')}
                    >
                        <FaRobot />
                    </button>
                </div>
                
                <div className="tab-content">
                    {activeTab === 'carriers' && (
                        <CarriersConversation 
                            conversation={carriersConversation} 
                            onValidateMessage={handleValidateCarrierMessage}
                            isValidating={validatingMessage}
                            onRefresh={fetchCarriersConversation}
                        />
                    )}
                    {activeTab === 'info' && (
                        <InfoPanel 
                            clientInfo={clientInfo} 
                            orderDetails={orderDetails} 
                            summary={summary} 
                        />
                    )}
                    {activeTab === 'wms' && (
                        <WMSChat />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MessageWindow;