import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line, Pie, Bar } from 'react-chartjs-2';
import { 
  FaStore, FaChartLine, FaUsers, FaBox, 
  FaMoneyBillWave, FaShoppingCart, FaGlobe,
  FaExclamationTriangle, FaTrophy, FaRegSadTear,
  FaExclamationCircle, FaSearch, FaImage, FaTag, FaBoxes
} from 'react-icons/fa';
import './ShopStatistics.css';
import { useNavigate } from 'react-router-dom';

const ShopStatistics = () => {
  const navigate = useNavigate();
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [attentionProducts, setAttentionProducts] = useState([]);
  const [isLoadingAttention, setIsLoadingAttention] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/shopify/get_shop_statistics', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStats(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchStats();
  }, []);

  useEffect(() => {
    const fetchAttentionProducts = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/shopify/get_products_needing_attention', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAttentionProducts(response.data.products);
      } catch (err) {
        console.error('Error fetching attention products:', err);
      } finally {
        setIsLoadingAttention(false);
      }
    };

    fetchAttentionProducts();
  }, []);

  if (error) return <div className="una-stats-error">Error: {error}</div>;
  if (!stats) return (
    <div className="una-stats-loading-screen">
      <div className="una-loading-content">
        <div className="una-loading-icon">
          <FaChartLine className="icon-primary" />
          <FaStore className="icon-secondary" />
        </div>
        <h2>Loading Statistics</h2>
        <div className="una-loading-progress">
          <div className="una-progress-bar">
            <div className="una-progress-glow"></div>
          </div>
        </div>
        <div className="una-loading-metrics">
          <div className="una-metric-item">
            <FaShoppingCart />
            <span>Analyzing Orders</span>
          </div>
          <div className="una-metric-item">
            <FaUsers />
            <span>Processing Customer Data</span>
          </div>
          <div className="una-metric-item">
            <FaBox />
            <span>Calculating Inventory</span>
          </div>
          <div className="una-metric-item">
            <FaMoneyBillWave />
            <span>Computing Revenue</span>
          </div>
        </div>
      </div>
    </div>
  );

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const renderOverviewSection = () => (
    <div className="una-stats-overview">
      <div className="una-stats-header">
        <div className="una-shop-info">
          <h1>{stats.general.shop_name}</h1>
          <p>{stats.general.shop_domain}</p>
          <span className="una-plan-badge">{stats.general.plan_name}</span>
        </div>
      </div>

      <div className="una-stats-grid">
        <div className="una-stat-card revenue">
          <FaMoneyBillWave />
          <div className="una-stat-content">
            <h3>Total Revenue</h3>
            <p>${stats.orders.total_revenue.toFixed(2)}</p>
            <span>Avg. Order: ${stats.orders.average_order_value.toFixed(2)}</span>
          </div>
        </div>

        <div className="una-stat-card orders">
          <FaShoppingCart />
          <div className="una-stat-content">
            <h3>Total Orders</h3>
            <p>{stats.orders.total_orders}</p>
            <span>Avg. Items: {stats.sales_analysis.average_items_per_order.toFixed(1)}</span>
          </div>
        </div>

        <div className="una-stat-card customers">
          <FaUsers />
          <div className="una-stat-content">
            <h3>Customers</h3>
            <p>{stats.customers.total_customers}</p>
            <span>New (30d): {stats.customers.new_customers_30d}</span>
          </div>
        </div>

        <div className="una-stat-card products">
          <FaBox />
          <div className="una-stat-content">
            <h3>Products</h3>
            <p>{stats.products.total_products}</p>
            <span>Variants: {stats.products.total_variants}</span>
          </div>
        </div>
      </div>

      <div className="una-alerts-section">
        {stats.products.out_of_stock_count > 0 && (
          <div className="una-alert out-of-stock">
            <FaExclamationTriangle />
            <span>{stats.products.out_of_stock_count} products out of stock</span>
          </div>
        )}
        {stats.products.low_stock_count > 0 && (
          <div className="una-alert low-stock">
            <FaExclamationTriangle />
            <span>{stats.products.low_stock_count} products low in stock</span>
          </div>
        )}
      </div>

      <div className="una-charts-grid">
        <div className="una-chart-card">
          <h3>Sales by Day</h3>
          <Bar 
            data={{
              labels: Object.keys(stats.sales_analysis.sales_by_day),
              datasets: [{
                label: 'Sales',
                data: Object.values(stats.sales_analysis.sales_by_day),
                backgroundColor: 'rgba(0, 255, 157, 0.2)',
                borderColor: 'rgba(0, 255, 157, 1)',
                borderWidth: 1
              }]
            }}
            options={chartOptions}
          />
        </div>

        <div className="una-chart-card">
          <h3>Orders by Month</h3>
          <Line 
            data={{
              labels: Object.keys(stats.orders.orders_by_month),
              datasets: [{
                label: 'Orders',
                data: Object.values(stats.orders.orders_by_month),
                borderColor: 'rgba(0, 255, 255, 1)',
                backgroundColor: 'rgba(0, 255, 255, 0.1)',
                tension: 0.4,
                fill: true
              }]
            }}
            options={chartOptions}
          />
        </div>
      </div>

      <div className="una-performance-section">
        <div className="una-performance-card">
          <h3><FaTrophy /> Best Selling Products</h3>
          <ul>
            {stats.sales_analysis.best_selling_products.map((product, index) => (
              <li 
                key={product.id} 
                onClick={() => handleProductClick(product.id)}
                className="una-clickable-item"
              >
                <span className="una-rank">#{index + 1}</span>
                <span className="una-product-title">{product.title}</span>
                <span className="una-sold-count">{product.total_sold} sold</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="una-performance-card">
          <h3><FaRegSadTear /> Needs Attention</h3>
          <ul>
            {stats.sales_analysis.worst_selling_products.map((product, index) => (
              <li 
                key={product.id}
                onClick={() => handleProductClick(product.id)}
                className="una-clickable-item"
              >
                <span className="una-product-title">{product.title}</span>
                <span className="una-sold-count">{product.total_sold} sold</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

  const renderAttentionSection = () => (
    <div className="una-attention-section">
      <h2 className="una-section-title">
        <FaExclamationCircle /> Products Needing Attention
      </h2>
      
      {isLoadingAttention ? (
        <div className="una-loading">Loading attention items...</div>
      ) : (
        <div className="una-attention-grid">
          {attentionProducts.map((product) => (
            <div 
              key={product.id} 
              className="una-attention-card una-clickable-item"
              onClick={() => handleProductClick(product.id)}
            >
              <div className="una-attention-header">
                <h3>{product.title}</h3>
                <span className="una-attention-type">{product.product_type}</span>
              </div>
              
              <div className="una-reasons-list">
                {product.health_status.reasons.map((reason, index) => (
                  <span key={index} className={`una-reason-tag ${reason}`}>
                    {formatReason(reason)}
                  </span>
                ))}
              </div>

              <div className="una-metrics-grid">
                {product.health_status.metrics.sales_30_days !== undefined && (
                  <div className="una-metric">
                    <FaShoppingCart />
                    <span>30d Sales: {product.health_status.metrics.sales_30_days}</span>
                  </div>
                )}
                {product.health_status.metrics.image_count !== undefined && (
                  <div className="una-metric">
                    <FaImage />
                    <span>Images: {product.health_status.metrics.image_count}</span>
                  </div>
                )}
                {product.variants && (
                  <div className="una-metric">
                    <FaBoxes />
                    <span>Variants: {product.variants.length}</span>
                  </div>
                )}
              </div>

              <div className="una-recommendations">
                <h4>Recommendations</h4>
                <ul>
                  {product.health_status.recommendations.map((rec, index) => (
                    <li key={index}>{rec}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const formatReason = (reason) => {
    const reasons = {
      out_of_stock: 'Out of Stock',
      low_stock: 'Low Stock',
      overstock: 'Overstock',
      low_sales_velocity: 'Low Sales',
      high_discount: 'High Discount',
      missing_handle: 'SEO Issue',
      thin_content: 'Thin Content',
      no_images: 'No Images',
      few_images: 'Few Images',
      no_tags: 'No Tags',
      single_variant: 'Single Variant'
    };
    return reasons[reason] || reason;
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: '#fff'
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: '#fff'
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: '#fff'
        }
      }
    }
  };

  return (
    <div className="una-shop-statistics">
      {renderOverviewSection()}
      {renderAttentionSection()}
    </div>
  );
};

export default ShopStatistics;