import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './dashboard.css';
import TicketTableDashboard from './TicketTableDashboard';
import { useTranslation } from 'react-i18next';
import { 
    FaTicketAlt, 
    FaRobot, 
    FaBoxOpen, 
    FaShoppingCart, 
    FaChartLine,
    FaExternalLinkAlt,
    FaExclamationCircle
} from 'react-icons/fa';

const Dashboard = () => {
    const [user, setUser] = useState(undefined);
    const [tickets, setTickets] = useState([]);
    const [monthlyUsage, setMonthlyUsage] = useState({ tickets_used: 0, ticket_limit: 0, extra_tickets_purchased: 0, remaining_extra_tickets: 0 });
    const [ticketStats, setTicketStats] = useState({
        automated_tickets: 0,
        pending_tickets: 0
    });
    const [orders, setOrders] = useState([]);
    const [returns, setReturns] = useState([]);
    const [attentionProducts, setAttentionProducts] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const wsRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);
    const [expandedCards, setExpandedCards] = useState({});

    const connectWebSocket = () => {
        const token = sessionStorage.getItem('access_token');
        if (!token) return;

        wsRef.current = new WebSocket(`/api/ws?token=${token}`);
        
        wsRef.current.onopen = (event) => {
            console.log('WebSocket connection established:', event);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }
        };

        wsRef.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        wsRef.current.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            console.log('Close code:', event.code);
            console.log('Close reason:', event.reason);
            reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
        };

        wsRef.current.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
            if (event.data === 'ping') {
                wsRef.current.send('pong');
            } else {
                fetchTickets(token);
            }
        };
    };

    useEffect(() => {
        const token = sessionStorage.getItem('access_token');
        if (!token) {
            navigate('/');
            return;
        }

        fetchUserData(token);
        fetchTickets(token);
        fetchMonthlyUsage(token);
        fetchTicketStats(token);
        fetchOrders(token);
        fetchAttentionProducts();
        fetchReturns(token);
        connectWebSocket();

        const pingInterval = setInterval(() => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send('ping');
            }
        }, 30000);

        return () => {
            clearInterval(pingInterval);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [navigate]);

    const fetchUserData = async (token) => {
        try {
            const response = await axios.get('/api/users/user', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUser(response.data);
            if (response.data?._id) {
                sessionStorage.setItem('user_id', response.data._id);
            }
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            setUser(null);
        }
    };

    const fetchMonthlyUsage = async (token) => {
        try {
            const response = await axios.get('/api/users/monthly-usage', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMonthlyUsage(response.data || { tickets_used: 0, ticket_limit: 0, extra_tickets_purchased: 0, remaining_extra_tickets: 0 });
        } catch (error) {
            console.error('Failed to fetch monthly usage:', error);
        }
    };

    const fetchTickets = async (token, page = 1, limit = 500) => {
        try {
            const response = await axios.get('/api/tickets/tickets', {
                headers: { Authorization: `Bearer ${token}` },
                params: { page, limit }
            });
            setTickets(response.data.tickets || []);
            // Handle pagination if needed
            // const { current_page, total_pages, total_tickets, has_next, has_previous } = response.data.pagination;
        } catch (error) {
            console.error('Failed to fetch tickets:', error);
        }
    };

    const fetchTicketStats = async (token) => {
        try {
            const response = await axios.get('/api/tickets/stats', {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('API response:', response.data);
            setTicketStats(response.data || {
                automated_tickets: 0,
                pending_tickets: 0
            });
        } catch (error) {
            console.error('Failed to fetch ticket stats:', error);
        }
    };

    const fetchOrders = async (token) => {
        try {
            const response = await axios.get('/api/shopify/get_orders', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setOrders(response.data.orders.slice(0, 5));
        } catch (error) {
            console.error('Failed to fetch orders:', error);
        }
    };

    const fetchReturns = async (token) => {
        try {
            const response = await axios.get('/api/webhook/returns', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setReturns(response.data.returns.slice(0, 5));
        } catch (error) {
            console.error('Failed to fetch returns:', error);
        }
    };

    const fetchAttentionProducts = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get('/api/shopify/get_products_needing_attention', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setAttentionProducts(response.data.products.slice(0, 5)); // Only get top 5
        } catch (error) {
            console.error('Failed to fetch attention products:', error);
        }
    };

    const handleTicketClick = async (ticketId) => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(`/api/tickets/${ticketId}/status`, null, {
                headers: { Authorization: `Bearer ${token}` }
            });
            navigate(`/tickets/${ticketId}/messages`);
        } catch (error) {
            console.error('Failed to update ticket status:', error);
        }
    };

    const usagePercentage = monthlyUsage.ticket_limit > 0 ? (monthlyUsage.tickets_used / monthlyUsage.ticket_limit) * 100 : 0;

    const toggleCard = (cardId) => {
        setExpandedCards(prev => ({
            ...prev,
            [cardId]: !prev[cardId]
        }));
    };

    const formatReason = (reason) => {
        const reasons = {
            out_of_stock: 'Out of Stock',
            low_stock: 'Low Stock',
            overstock: 'Overstock',
            low_sales_velocity: 'Low Sales',
            high_discount: 'High Discount',
            missing_handle: 'SEO Issue',
            thin_content: 'Thin Content',
            no_images: 'No Images',
            few_images: 'Few Images',
            no_tags: 'No Tags',
            single_variant: 'Single Variant'
        };
        return reasons[reason] || reason;
    };

    return (
        <div className="una-dashboard-container">
            <div className="una-dashboard-header">
                <div className="una-dashboard-stats-grid">
                    {/* Quick Stats Cards */}
                    <div className="una-stat-card">
                        <div className="una-stat-icon">
                            <FaTicketAlt />
                        </div>
                        <div className="una-stat-content">
                            <span className="una-stat-value">{ticketStats.remaining_tickets}</span>
                            <span className="una-stat-label">{t('remainingTickets')}</span>
                        </div>
                    </div>

                    <div className="una-stat-card">
                        <div className="una-stat-icon automated">
                            <FaRobot />
                        </div>
                        <div className="una-stat-content">
                            <span className="una-stat-value">{ticketStats.automated_tickets}</span>
                            <span className="una-stat-label">{t('automatedTickets')}</span>
                        </div>
                    </div>

                    <div className="una-stat-card">
                        <div className="una-stat-icon pending">
                            <FaTicketAlt />
                        </div>
                        <div className="una-stat-content">
                            <span className="una-stat-value">{ticketStats.pending_tickets}</span>
                            <span className="una-stat-label">{t('pendingTickets')}</span>
                        </div>
                    </div>

                    <div className="una-stat-card">
                        <div className="una-stat-icon">
                            <FaChartLine />
                        </div>
                        <div className="una-stat-content">
                            <div className="una-usage-bar">
                                <div 
                                    className="una-usage-progress" 
                                    style={{width: `${usagePercentage}%`}}
                                />
                            </div>
                            <span className="una-stat-label">
                                {monthlyUsage.tickets_used}/{monthlyUsage.ticket_limit} {t('used')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="una-dashboard-main">
                <div className="una-dashboard-grid">
                    {/* Tickets Section - 1/3 width */}
                    <div className="una-dashboard-card">
                        <div className="una-card-header">
                            <FaTicketAlt className="tickets-icon" />
                            <h3>{t('activeTickets')}</h3>
                            <button onClick={() => navigate('/tickets')} className="una-view-all">
                                <FaExternalLinkAlt />
                            </button>
                        </div>
                        <div className="una-card-content">
                            {tickets
                                .filter(t => ['pending', 'automated'].includes(t.status))
                                .slice(0, 10)
                                .map(ticket => (
                                    <div key={ticket._id} className="una-list-item" onClick={() => navigate(`/tickets/${ticket._id}/messages`)}>
                                        <div className="una-ticket-info">
                                            <span className="una-ticket-subject">{ticket.subject}</span>
                                            <span className={`una-status-badge ${ticket.status}`}>
                                                {ticket.status}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    {/* Orders & Returns Section - 2/3 width */}
                    <div className="una-activity-section">
                        {/* Orders Card */}
                        <div className="una-dashboard-card">
                            <div className="una-card-header">
                                <FaShoppingCart className="orders-icon" />
                                <h3>{t('recentOrders')}</h3>
                                <button onClick={() => navigate('/orders')} className="una-view-all">
                                    <FaExternalLinkAlt />
                                </button>
                            </div>
                            <div className="una-card-content">
                                {orders.slice(0, 10).map(order => (
                                    <div key={order.id} className="una-list-item" onClick={() => navigate(`/order/${order.id}`)}>
                                        <div className="una-order-info">
                                            <div className="una-order-main">
                                                <span className="una-order-number">#{order.order_number}</span>
                                                <span className="una-order-customer">{order.customer?.name}</span>
                                            </div>
                                        </div>
                                        <div className="una-order-details">
                                            <span className="una-order-price">${order.total_price}</span>
                                            <span className={`una-status-badge ${order.financial_status}`}>
                                                {order.financial_status}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Returns Card */}
                        <div className="una-dashboard-card">
                            <div className="una-card-header">
                                <FaBoxOpen className="returns-icon" />
                                <h3>{t('recentReturns')}</h3>
                                <button onClick={() => navigate('/returns')} className="una-view-all">
                                    <FaExternalLinkAlt />
                                </button>
                            </div>
                            <div className="una-card-content">
                                {returns.slice(0, 10).map(returnItem => (
                                    <div 
                                        key={returnItem._id} 
                                        className="una-activity-item"
                                        onClick={() => navigate(`/return/${returnItem._id}`)}
                                        role="button"
                                        tabIndex={0}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                                navigate(`/return/${returnItem._id}`);
                                            }
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div className="una-activity-item-main">
                                            <div className="una-activity-item-content">
                                                <div className="una-activity-item-title">
                                                    {returnItem.items?.[0]?.title || 'No title'}
                                                </div>
                                                <div className="una-activity-item-subtitle">
                                                    Order #{returnItem.order_id} • {returnItem.tracking_number}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="una-activity-item-side">
                                            <span className="una-activity-item-price">
                                                €{(returnItem.items?.[0]?.price || 0).toFixed(2)}
                                            </span>
                                            <span className={`una-status-badge ${returnItem.status.replace('_', ' ').replace(' ', '-').toLowerCase()}`}>
                                                {returnItem.status.replace('_', ' ')}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="una-attention-section">
                <div className="una-dashboard-card">
                    <div className="una-card-header">
                        <FaExclamationCircle />
                        <h3>{t('productsNeedingAttention')}</h3>
                        <button onClick={() => navigate('/statistics')} className="una-view-all">
                            <FaExternalLinkAlt />
                        </button>
                    </div>
                    <div className="una-card-content">
                        {attentionProducts.map(product => (
                            <div 
                                key={product.id} 
                                className="una-list-item"
                                onClick={() => navigate(`/product/${product.id}`)}
                            >
                                <div className="una-product-info">
                                    <span className="una-product-title">{product.title}</span>
                                    <div className="una-product-tags">
                                        {product.health_status.reasons.map((reason, index) => (
                                            <span key={index} className={`una-reason-tag ${reason}`}>
                                                {formatReason(reason)}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="una-product-metrics">
                                    {product.health_status.metrics.sales_30_days !== undefined && (
                                        <span className="una-metric">
                                            <FaShoppingCart />
                                            {product.health_status.metrics.sales_30_days}
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;