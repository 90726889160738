import React, { useState } from 'react';
import './TicketTableDashboard.css';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaFacebookMessenger, FaWhatsapp, FaEnvelope, FaGlobe, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

const TicketTableDashboard = ({ tickets, user, onTicketClick }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortField, setSortField] = useState('created_at');
  const [statusFilter, setStatusFilter] = useState('');

  const ticketsPerPage = 20;

  const channelIcons = {
    Instagram: FaInstagram,
    Messenger: FaFacebookMessenger,
    WhatsApp: FaWhatsapp,
    Gmail: FaEnvelope,
    Website: FaGlobe
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const sortTickets = (tickets, field, order) => {
    return [...tickets].sort((a, b) => {
      const valA = a[field];
      const valB = b[field];
      if (field === 'created_at') {
        return order === 'asc' ? new Date(valA) - new Date(valB) : new Date(valB) - new Date(valA);
      }
      if (field === 'sentiment_score') {
        return order === 'asc' ? valA - valB : valB - valA;
      }
      return order === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
    });
  };

  const filterTickets = (tickets) => {
    return tickets.filter(ticket => {
      const statusMatch = statusFilter ? ticket.status === statusFilter : true;
      return statusMatch;
    });
  };

  const sortedTickets = sortTickets(filterTickets(tickets), sortField, sortOrder);

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = sortedTickets.slice(indexOfFirstTicket, indexOfLastTicket);
  const totalPages = Math.ceil(sortedTickets.length / ticketsPerPage);

  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSortChange = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const getSentimentScoreClass = (score) => {
    if (score <= 25) return 'low';
    if (score <= 60) return 'medium';
    return 'high';
  };

  const SortIcon = ({ field }) => {
    if (sortField !== field) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <div className="ticket-table-dashboard">
      <div className="table-controls">
        <div className="status-filter">
          <label htmlFor="status-select">{t('status')}:</label>
          <select
            id="status-select"
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <option value="">{t('all')}</option>
            <option value="pending">{t('pending')}</option>
            <option value="automated">{t('automated')}</option>
          </select>
        </div>
        <div className="pagination">
          <button 
            onClick={() => handlePageChange('prev')} 
            disabled={currentPage === 1}
          >
            {t('previous')}
          </button>
          <span>{currentPage} / {totalPages}</span>
          <button 
            onClick={() => handlePageChange('next')} 
            disabled={currentPage === totalPages}
          >
            {t('next')}
          </button>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>{t('channel')}</th>
            <th>{t('subject')}</th>
            <th onClick={() => handleSortChange('sentiment_score')} className="sortable">
              {t('sentimentScore')} <SortIcon field="sentiment_score" />
            </th>
            <th>{t('tags')}</th>
            <th>{t('status')}</th>
            <th onClick={() => handleSortChange('created_at')} className="sortable">
              {t('createdAt')} <SortIcon field="created_at" />
            </th>
          </tr>
        </thead>
        <tbody>
          {currentTickets.map((ticket) => {
            const ChannelIcon = channelIcons[ticket.channel_name];
            return (
              <tr key={ticket._id} onClick={() => onTicketClick(ticket._id)}>
                <td>
                  {ChannelIcon && <ChannelIcon className="channel-icon" />}
                  <span>{ticket.channel_name}</span>
                </td>
                <td>{ticket.subject}</td>
                <td>
                  <span className={`sentiment-score ${getSentimentScoreClass(ticket.sentiment_score)}`}>
                    {Math.round(ticket.sentiment_score)}
                  </span>
                </td>
                <td>
                  <div className="tags-container">
                    {ticket.tags && ticket.tags.map((tag, index) => (
                      <span key={index} className="ticket-tag">{tag}</span>
                    ))}
                  </div>
                </td>
                <td>
                  <span className={`status-badge ${ticket.status}`}>
                    {t(ticket.status)}
                  </span>
                </td>
                <td>{formatDate(ticket.created_at)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TicketTableDashboard;